<template>
  <div class="members-card">
    <div class="members-card__delete" v-if="isBlocked && getUser?.role === 'Admin'" @click="isBlocked ? visibleBlockAlert = true : ''">
      <img src="@/assets/svg/icon-delete-member.svg" v-if="isBlocked"/>
    </div>
    <router-link :to="`${ getUser.id === data.id ? '/profile' : '/user/' + data.id }`"  class="content">
      <div class="content__left">
        <img class="content__left-avatar" :src="getUserAvatar(data.avatarSrc.fileSrc)" alt="">
        <div class="content__left-info">
          <p class="info-nickname">{{ getTitle }}</p>
          <div class="info-roll" v-if="!isDelete">
            <div class="info-roll-top">
              <img :src="getUserRole(data.role).icon" alt="">
              <p>{{ $t(getUserRole(data.role).text) }}</p>
            </div>
            <span
                v-if="
                (getUser.role !== 'Company' && getUser.role !== 'Government') &&
                data.role === 'Government' || data.role === 'Company' &&
                getUserName(data.firstName, data.lastName).length > 1"
            >
              {{ getUserName( data.lastName, data.firstName, data?.middleName) }}</span>
          </div>
          <!--          <ui-rating class="info-rating" v-model="data.starRating" size="14" v-if="data.role === 'Inspector' && $route.query.status !== 'new'" />-->
          <div class="content__left-info-location" v-if="!isDelete">
            <div class="location">
              <img src="../../../assets/svg/icon-location.svg" alt="" />
              <p>{{ data?.city || data?.region?.name }}</p>
            </div>
            <p class="location-text" v-if="getUser.role !== 'Company' && getUser.role !== 'Government'">{{ $t('length-requests-closed-total', {from: data.totalOrdersCount - data.acceptedOrdersCount, to: data.totalOrdersCount}) }}</p>
          </div>
          <ui-rating class="info-stars" v-model="data.starRating" size="14" v-if="data.role === 'Inspector' && $route.query.status !== 'new'"/>
        </div>
      </div>
      <div class="content__right" v-if="!isDelete">
        <div class="content__right-location">
          <div class="location">
            <img src="../../../assets/svg/icon-location.svg" alt="" />
            <p>{{ data?.city || data?.region?.name }}</p>
          </div>
          <p class="location-text" v-if="getUser.role !== 'Company' && getUser.role !== 'Government'">
            {{ data.totalOrdersCount - data.acceptedOrdersCount }}/{{ data.totalOrdersCount }}
            {{ $t('requests-close-total-2') }}
            <ui-rating v-model="data.starRating" size="14" v-if="data.role === 'Inspector' && $route.query.status !== 'new'"/>
          </p>
        </div>
        <p class="content__right-mob-text" v-if="getUser.role !== 'Company' && getUser.role !== 'Government' && $route.query.status === 'new'">{{ $t('length-requests-closed-total', {from: data.totalOrdersCount - data.acceptedOrdersCount, to: data.totalOrdersCount}) }}</p>
        <div class="content__right-rating" v-if="data.role === 'Inspector' && $route.query.status !== 'new'">
          <p class="rating-chip">{{ data.score }}</p>
          <p class="rating-text" v-if="getUser.role !== 'Company' && getUser.role !== 'Government'">{{ data.acceptedOrdersCount }}/{{ data.totalOrdersCount }} обращений закрытых/всего</p>
        </div>
      </div>
    </router-link>
    <img
        @click="visibleWindow = !visibleWindow"
        v-if="data.status === 'New' && (getUser.role === 'Moderator' || getUser.role === 'Admin') && visibleActions && !isDelete && !isBlocked"
        src="@/assets/svg/arrows/up-black.svg"
        :class="['members-card__arrow', { rotate: visibleWindow }]"
    />
    <transition name="slide-top">
      <div v-if="visibleWindow && visibleActions && !isBlocked" class="members-card__window">
        <ui-textarea v-model="textarea" height="65" radius="10" :label="$t('reason-for-rejection')" :required-field="true" />
        <ui-button @click="declineUser" :disabled="!textarea.length" color="error">{{ $t('reject') }}</ui-button>
      </div>
    </transition>
    <div v-if="data.status === 'New' && (getUser.role === 'Moderator' || getUser.role === 'Admin') && visibleActions && !isDelete && !isBlocked" class="members-card__action">
      <ui-button @click="acceptUser">{{ $t('accept') }}</ui-button>
    </div>
    <UiAlert v-model="visibleBlockAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-you-really-want-to-unblock') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="unBlock" color="error">{{ $t('unblock-this-participant') }}</ui-button>
        <ui-button @click="visibleBlockAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { getUserAvatar, getUserRole } from '@/utils/user'
import UiAlert from "@/components/ui/UiAlert.vue";
import {getDate} from "@/utils/general";
export default {
  name: "MembersCard",
  components: {
    UiAlert,
    UiTextarea: () => import('@/components/ui/UiTextarea'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiRating: () => import('@/components/ui/UiRating')
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showAction: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: false
    },
    isBlocked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      getUserAvatar,
      getUserRole,
      visibleActions: true,
      visibleWindow: false,
      textarea: '',
      visibleBlockAlert: false
    }
  },

  methods: {
    ...mapActions(['userAccept', 'userDecline', 'editUser']),

    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      } else if(name && surname) {
        return name + ' ' + surname.split('')[0].toUpperCase()
      } else if(name) {
        return name
      } else {
        return this.$t('empty')
      }
    },

    acceptUser() {
      this.userAccept(this.data.id).then(() => {
        this.visibleActions = false
      })
    },

    declineUser() {
      this.userDecline({
        id: this.data.id,
        roleRejectComment: this.textarea
      }).then(() => {
        this.visibleActions = false
      })
    },

    unBlock () {
      this.visibleBlockAlert = false;
      this.editUser({
        acceptedOrdersCount: this.data.acceptedOrdersCount,
        avatar: this.data.avatar,
        avatarSrc: this.data.avatarSrc,
        birthDate: this.data.birthDate,
        city: this.data.city,
        companyName: this.data.companyName,
        deletedAt: this.data.deletedAt,
        district: this.data.district,
        districtId: this.data.districtId,
        documentFile: this.data.documentFile,
        documentIssueDate: getDate(this.data.documentIssueDate),
        documentNumber: this.data.documentNumber,
        documentsSrc: this.data.documentsSrc,
        email: this.data.email,
        firstName: this.data.firstName,
        id: this.data.id,
        isBlocked: false,
        lastName: this.data.lastName,
        login: this.data.login,
        middleName: this.data.middleName,
        mustChangePassword: this.data.mustChangePassword,
        phone: this.data.phone,
        region: this.data.region,
        regionId: this.data.regionId,
        role: this.data.role,
        roleRejectComment: this.data.roleRejectComment,
        score: this.data.score,
        starRating: this.data.starRating,
        status: this.data.status,
        taxNumber: this.data.taxNumber,
        totalOrdersCount: this.data.totalOrdersCount,
      }).then(() => {
        this.$emit('updatePage')
      })
    }
  },

  computed: {
    ...mapGetters(['getUser']),

    getTitle() {
      return this.data.role === 'Inspector' || this.data.role === 'Lawyer' || this.isDelete ? this.getUserName(this.data.lastName, this.data.firstName, this.data.middleName) : '' ||
      this.data.role === 'Company' ? this.data.companyName : '' ||
      this.data.role === 'Government' ? this.data.companyName : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.members-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;

  &__delete {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    z-index: 10;

    img {
      position: absolute;
      left: 40px;
      top: 26px;
      z-index: 11;

      @media (max-width: 992px) {
        width: 32px;
        height: 32px;
        left: 22px;
        top: 30px;
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: 51% 49%;
    padding: 12px 20px 11px 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    width: 100%;
    z-index: 2;

    @media (max-width: 1350px) {
      padding: 12px 80px 11px 20px;
    }

    @media (max-width: 1200px) {
      padding: 12px 20px 11px 20px;
    }

    @media (max-width: 992px) {
      padding: 17px 20px 11px 10px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: 480px) {
      padding: 7px 17px 7px 10px;
      height: auto;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 20px;
      max-width: 394px;
      width: 100%;
      position: relative;

      @media (max-width: 992px) {
        gap: 10px;
      }

      &-avatar {
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 992px) {
          min-width: 50px;
          max-width: 50px;
          min-height: 50px;
          max-height: 50px;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 992px) {
          gap: 5px;
        }

        .info-stars {
          display: none;

          @media (max-width: 992px) {
            display: inline-block;
          }
        }

        .info-nickname {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #343432;
          font-family: 'Gotham Pro Medium';

          @media (max-width: 480px) {
            font-size: 12px;
            line-height: 13px;
          }
        }

        .info-rating {
          display: none;
          @media (max-width: 992px) {
            display: flex;
          }
        }

        .info-roll {
          display: flex;
          align-items: flex-start;
          gap: 5px;

          @media (max-width: 992px) {
            flex-direction: row;
            align-items: center;
          }

          &-top {
            display: flex;
            gap: 5px;

            img {
              width: 16px;
              height: 16px;

              @media (max-width: 992px) {
                display: none;
              }
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 120.2%;
              color: #1B1A1F;
              font-family: 'Gotham Pro Regular';

              @media (max-width: 992px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 11px;
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 120.2%;
            color: #1B1A1F;
            font-family: "Gotham Pro Regular";

            @media (max-width: 992px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 11px;
            }

            &::before {
              content: '';
              width: 3px;
              height: 3px;
              background: #1B1A1F;
              border-radius: 50%;
            }
          }
        }

        &-location{
          display: none;
          align-items: center;
          gap: 10px;

          @media (max-width: 992px) {
            display: flex;
          }
          .location {
            display: flex;
            align-items: center;
            gap: 5px;

            img {
              width: 8px;
              height: 10px;
            }

            p {
              font-weight: 400;
              font-size: 10px;
              line-height: 10px;
              color: #343432;
              font-family: "Gotham Pro Regular";
            }
          }

          .location-text{
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            color: #9A9A9A;
            white-space: nowrap;

            @media (max-width: 992px) {
              display: none;
            }
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      position: relative;

      @media (max-width: 992px) {
        width: auto;
      }

      &-mob-text {
        display: none;
        margin-top: -42px;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        text-align: right;
        color: #9A9A9A;
        width: 90px;
        font-family: "Gotham Pro Regular";

        @media (max-width: 992px) {
          display: inline-block;
        }
      }

      &-location{
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 992px) {
          display: none;
        }

        .location {
          display: flex;
          align-items: self-start;
          gap: 5px;
          width: 270px;

          @media (max-width: 1300px) {
            width: 230px;
          }

          p {
            font-size: 16px;
            font-family: 'Gotham Pro Regular';
            font-weight: 400;
            line-height: 120.2%;
          }
        }

        .location-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 120.2%;
          color: #9A9A9A;
          text-align: start;
          width: 140px;
          font-family: "Gotham Pro Regular";
          display: flex;
          flex-direction: column;
        }
      }

      &-rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        @media (max-width: 1400px) {
          gap: 10px;
          padding-right: 20px;
        }

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: flex-end;
          gap: 8px;
          padding-right: 0;
        }

        .rating-chip {
          padding: 12px 12px 9px 12px;
          background: #E21F1F;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #FFFFFF;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;

          @media (max-width: 480px) {
            padding: 8px 8px 7px 8px;
          }
        }

        .rating-text {
          display: none;
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;
          text-align: right;
          font-family: "Gotham Pro Regular";
          color: #9A9A9A;
          width: 90px;

          @media (max-width: 992px) {
            display: flex;
          }
        }
      }
    }
  }
  &__action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    min-width: 244px;

    @media (max-width: 992px) {
      width: 100%;
      padding: 0 12px;
    }
    button {
      max-width: 429px;

      @media (max-width: 992px) {
        height: 40px;
      }
    }
  }

  &__window {
    width: 100%;
    max-width: 960px;
    display: flex;
    gap: 20px;
    background: #FFFFFF;
    border: 1px dashed #D9D9D9;
    border-radius: 10px;
    padding: 54px 30px 30px 30px;
    margin-top: -14px;
    transition: 0.3s;

    @media (max-width: 992px) {
      flex-direction: column;
      padding: 42px 20px 30px 20px;
      margin-top: -32px;
    }

    ::v-deep .button {
      max-width: 379px;
      width: 100%;
      margin-top: 20px;

      @media (max-width: 992px) {
        margin-top: 0;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 36px;
    right: 30px;
    z-index: 3;
    cursor: pointer;
    transform: rotate(180deg);
    transition: 0.5s;

    @media (max-width: 992px) {
      top: 45px;
      right: 19px;
    }
  }

  .rotate {
    transform: rotate(0deg) !important;
  }
}
::v-deep .ui-rating {
  img {
    width: 25px;
    height: 25px;

    @media(max-width: 992px) {
      width: 14px !important;
      height: 14px !important;
    }
  }
}
::v-deep .ui-textarea {
  max-width: 500px;
}
::v-deep .button {
  max-height: 40px !important;
}
</style>
